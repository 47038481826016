import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"
import { v4 as uuidv4 } from "uuid"

import Link from "../UI/Link"
import slugify from "../../utils/slugify"
import {
  sectionClass,
  successSectionClass,
  rowClass,
  formControlClass,
  successContainerClass,
  successMsgClass,
  sendBtnClass,
  checkboxGroupClass,
  checkboxContainerClass,
  checkmarkClass
} from "./CallbackRequestForm.module.scss"

export const fragment = graphql`
  fragment callbackRequestFormFragment on WpPage_Flexlayouts_FlexibleLayouts_CallbackRequestForm {
    requestOptions {
      option
    }
    conditionsLink {
      ... on WpPage {
        link
      }
    }
    privacyLink {
      ... on WpPage {
        link
      }
    }
    submitMessage
    emailTo
  }
`

const CallbackRequestForm = ({ requestOptions, conditionsLink, privacyLink, submitMessage, emailTo }) => {
  let mailTo = emailTo || process.env.ORG_EMAIL
  
  const [status, setStatus] = useState({ initial: true })
  const [selectedOptions, setSelectedOptions] = useState({})
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const handleGroupCheckboxClick = ({ target: { id } }) => {
    setSelectedOptions(selected => ({ ...selected, [id]: !selected[id] }))
  }


  const validate = event => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }


  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget
    let selectedRequests = ""
    
    for (let key in selectedOptions) {
      if (selectedOptions[key]) {
        let option = key.replace(/\-/g, " ")
        option = option[0].toUpperCase() + option.slice(1)
        selectedRequests += `${option}; `
      }
    }

    selectedRequests = selectedRequests.replace(/;\s?$/, ".")

      const data = {
        firstname: form.elements.firstname.value,
        lastname: form.elements.lastname.value,
        email: form.elements.email.value,
        phone: form.elements.phone.value,
        requestOptions: selectedRequests,
        message: form.elements.message.value,
        emailTo: mailTo
      }

      const postForm = async () => {
        try {
          const response = await axios.post(
            `${process.env.CALLBACK_FORM_URL}`,
            queryString.stringify(data)
          )
          setStatus({
            success: submitMessage,
            initial: false
          })
        } catch (exception) {
          setStatus({
            ...status,
            exception: `${exception.response.status}: ${exception.response.statusText}`
          })
        }
      }
      postForm()
  }

  return (
    <section
      className={status && status.success ? successSectionClass : sectionClass}>
      {status && status.initial && (
        <Container className="py-5 bg-purple">
          <Row className={rowClass}>
            <Col md={10} className="mx-auto">
              <Form
                id="callbackRequestForm"
                noValidate
                onChange={validate}
                onSubmit={handleSubmit}
                method="post">
                <>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstname">
                        <Form.Label for="firstname">First Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="firstname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="lastname">
                        <Form.Label for="lastname">Last Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="lastname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label for="email">Email Address*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="email"
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="phone">
                        <Form.Label for="phone">Phone number*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="phone"
                          type="tel"
                          minlength="11"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-mid4">
                    <Col className="mb-3">
                      <p>
                      How can we assist you?*
                      </p>
                    </Col>
                    {requestOptions.map(item => {
                      const isChecked = selectedOptions[slugify(item.option)]

                      return (
                        <Col md={6} key={uuidv4()}>
                          <div
                            className={`${checkboxGroupClass} form-group form-check`}>
                            <label
                              className={`${checkboxContainerClass} form-check-label text-start`}
                              htmlFor={slugify(item.option)}>
                              {isChecked ? (
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  id={slugify(item.option)}
                                  name={slugify(item.option)}
                                  onChange={handleGroupCheckboxClick}
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  id={slugify(item.option)}
                                  name={slugify(item.option)}
                                  onChange={handleGroupCheckboxClick}
                                />
                              )}
                              <span className={checkmarkClass}/>
                              {item.option}
                            </label>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="message">
                        <Form.Label for="message">Your message*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="message"
                          as="textarea"
                          rows={4}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} lg={7}>
                      <div
                        className={`${checkboxGroupClass} form-group form-check`}>
                        <label
                          className={`${checkboxContainerClass} form-check-label text-start`}
                          htmlFor="callback-terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="callback-terms"
                            name="terms"
                            required
                          />
                          <span className={checkmarkClass}/>I agree to the
                          EPT{" "}
                          <Link
                            classes="linkClass"
                            link={privacyLink?.link}
                            title="Privacy Policy"
                          />
                          {/*{" "} and{" "}
                          <Link
                            classes="linkClass"
                            link={conditionsLink?.link}
                            title="Terms"
                          /> */}
                          {"."}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button
                          type="submit"
                          className={sendBtnClass}
                          disabled={submitDisabled}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass}>{status.success}</div>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}

export default CallbackRequestForm