/**
 * Turns a string into a valid slug.
 * @see: https://gist.github.com/codeguy/6684588
 * @param {*} string
 */

function slugify(str) {
  let string = str.replace(/^\s+|\s+$/g, "") // trim
  string = string.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  const to = "aaaaeeeeiiiioooouuuunc------"
  for (let i = 0, l = from.length; i < l; i += 1) {
    string = string.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  string = string
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return string
}

export default slugify
